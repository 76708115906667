:root {
    --color-red-lighten-3: #ffd6db;
    --color-red-lighten-2: #ffadb7;
    --color-red-lighten-1: #ff8593;
    --color-red: #ff4c60;
    --color-red-darken-1: #ff334b;
    --color-red-darken-2: #ff0a27;
    --color-red-darken-3: #e0001a;
    --color-yellow-lighten-3: #fff3d6;
    --color-yellow-lighten-2: #ffe8ad;
    --color-yellow-lighten-1: #ffdc85;
    --color-yellow: #ffd15c;
    --color-yellow-darken-1: #ffc533;
    --color-yellow-darken-2: #ffba0a;
    --color-yellow-darken-3: #e0a100;
    --color-blue-lighten-3: #dcdcf9;
    --color-blue-lighten-2: #babaf3;
    --color-blue-lighten-1: #9797ed;
    --color-blue: #6c6ce5;
    --color-blue-darken-1: #5252e0;
    --color-blue-darken-2: #2f2fda;
    --color-blue-darken-3: #2222bf;
    --color-green-lighten-3: #abeddf;
    --color-green-lighten-2: #89e6d2;
    --color-green-lighten-1: #68dfc5;
    --color-green: #44d7b6;
    --color-green-darken-1: #2bcaa7;
    --color-green-darken-2: #24a88c;
    --color-green-darken-3: #1d8770;
    --color-black-lighten-3: #6b67a2;
    --color-black-lighten-2: #59558b;
    --color-black-lighten-1: #494672;
    --color-black: #353353;
    --color-black-darken-1: #28273f;
    --color-black-darken-2: #181726;
    --color-black-darken-3: #08080d;
    --color-white: #f9f9ff;
    --color-white-darken-1: #ebebff;
    --color-gray-lighten-3: #dbdae7;
    --color-gray-lighten-2: #c3c1d7;
    --color-gray-lighten-1: #aba9c7;
    --color-gray: #8b88b1;
    --color-gray-darken-1: #7a77a6;
    --color-gray-darken-2: #646194;
    --color-gray-darken-3: #53517b;
}
